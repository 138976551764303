const en = {
  translation: {
    // define translations below
    form: {
      required: 'This field is required.',
      min: 'Required number of characters is invalid.',
      max: 'Required number of characters is invalid.',
      email: 'The email format is invalid.',
      password: {
        minLength: 'Password must be at least 8 characters.',
        confirm: 'Password confirmation does not match.',
        strong:
          'Password must contain the following: 1 uppercase, 1 special character and a minimum of 8 characters.',
      },
    },
    labels: {
      first_name: 'First Name',
      last_name: 'Last Name',
      login: 'Login',
      signup: 'Signup',
      remember_me: 'Remember Me',
      forgot_password: 'Forgot Password?',
      email_address: 'Email Address',
      password: 'Password',
      confirm_password: 'Confirm Password',
      submit: 'Submit',
      update: 'Update',
      save: 'Save',
      add_new: 'Add New',
      reset_password: 'Reset Password',
      new_password: 'New Password',
      confirm_new_password: 'Confirm New Password',
      enter_keyword: 'Enter Keyword',
      get_started: 'Get Started',
      integrations: 'Integrations',
      settings: 'Settings',
      documentation: 'Documentation',
      fullname: 'Fullname',
      inquiry_content: 'Inquiry Content',
      navigation: 'Navigation',
      resources: 'Resources',
      cancel: 'Cancel',
      action: 'Action',
      showPassword: 'Show Password',
      hidePassword: 'Hide Password',
      role: 'Role',
      notifications: 'Notifications',
      noNotifications: 'No new notifications.',
      newNotification: 'You have a new notification.',
      seeMore: 'もっと見る',
      mm: '人月',
    },
    pages: {
      signup: {
        agree_to_terms: 'By clicking Register, you agree that you have read and agree to the',
        signup_complete:
          'A confirmation email has been sent to your inbox. Click the link to complete the registration process.',
        terms_conditions: 'Terms & Conditions',
        create_free_account: 'Create your Free Account',
      },
      forgot_password: {
        sub_heading: 'To recover your account, please enter your email address below.',
        success: 'Check your inbox for instructions on how to reset your password.',
      },
      reset_password: {
        sub_heading: 'Please enter your new password.',
        success: 'The password has been updated successfully.',
      },
      users: {
        user_created: 'The user has been created.',
        user_updated: 'User details have been updated.',
        user_deleted: 'User has been deleted.',
        add_user: 'Add User',
        edit_user: 'Edit User',
        delete_user: 'Delete user',
        first_name: 'First Name',
        last_name: 'Last Name',
        email_address: 'Email Address',
        status: 'Status',
        role: 'Role',
        delete_confirmation: 'Are you sure you want to delete the selected user?',
      },
      activate: {
        heading: 'Activate Account',
        subtitle: 'Set your password to activate your account.',
        activated: 'Your Account has been activated. You can now login to your account!',
      },
      dashboard: {
        main_heading: 'Welcome to React Base Template!',
        sub_heading: 'A lightweight boilerplate about the development of a React project.',
        new_users: 'New Users',
        total_sales: 'Total Sales',
        total_orders: 'Total Orders',
      },
      not_found: {
        title: 'Page Not Found',
        sub_heading:
          'The page you are looking for may have been deleted or moved to another location.',
        back_to_top: 'Back to Top Page',
      },
      faq: {
        heading: 'FAQ',
        sub_heading: 'We have summarized the questions that customers inquire in a Q&A format.',
      },
      inquiry: {
        heading: 'Inquiry',
        sub_heading: 'If you would like to contact us, please fill out the form below.',
        success_message: 'Your inquiry has been sent.',
        failed_message: 'An error occurred while sending.',
      },
      profile: {
        heading: 'Edit Profile',
        sub_heading: 'Update your account information.',
        success_message: 'Details has been updated successfully.',
        failed_message: 'The update failed.',
      },
      landing: {
        main_heading: 'Welcome to React Base Template!',
        sub_heading: 'A lightweight boilerplate about the development of a React project.',
        why_heading: 'Why use Base Template?',
        docker: {
          heading: 'Flexible Environment',
          description:
            'Eliminate the "it works on my machine" problem once and for all. Spend less time setting up environments, debugging environment-specific issues, and a more portable and easy-to-set-up codebase.',
        },
        react: {
          heading: 'Fast & Intuitive UI',
          description:
            'ReactJS is extremely intuitive to work with and provides interactivity to the layout of any UI. It is composable so we can utilize these components and integrate them into one place. Hence the code becomes a lot more maintainable and flexible.',
        },
        laravel: {
          heading: 'Powerful API',
          description:
            "Utilizing Laravel's API feature for easy backend API development. It's quick, simple with easy 3rd Party integrations and libraries.",
        },
        our_customers_heading: 'Our Clients',
        reviews_heading: 'What our clients say',
        see_all_reviews: 'See All Reviews',
        call_to_action: 'Accelerate your application development today!',
      },
      about: {
        main_heading: 'Our Story',
        sub_heading:
          'We work together to design, create and produce works that we are proud of for those we believe in.',
        meet_the_team: 'Meet the team',
        team_description:
          'Thoughtfulness, originality, and attention to detail are the basis for every product we design, build, and market.',
        our_mission: 'Our Mission',
        mission_description:
          'Our mission is to spread the excellence of technology with quality service and products valuing the business trend and proposition with people centric culture and behavior.',
        our_activities: 'Our Activities',
        activities_description: 'Never get so busy making a living that you forget to make a life.',
      },
      roles: {
        role_created: 'The role has been created.',
        role_updated: 'Role details have been updated.',
        role_deleted: 'Role has been deleted.',
        add_role: 'Add Role',
        edit_role: 'Edit Role',
        delete_role: 'Delete Role',
        name: 'Name',
        permissions: 'Permissions',
        delete_confirmation: 'Are you sure you want to delete the selected role?',
      },
      unauthorized: {
        main_heading: 'Unauthorized',
        sub_heading: 'Sorry, you do not have permission to access this resource.',
      },
      quotation_list: {
        heading: 'Quotation List',
        sub_heading: 'quotation-If you would like to contact us, please fill out the form below.',
        success_message: 'Your inquiry has been sent.',
        failed_message: 'An error occurred while sending.',
        return_to_sprobe: 'SPROBEサイトへ帰る',
        project_list_heading: '事前定義された見積もりテンプレートのリスト',
        noData: 'No data found.',
        sub_header_section: {
          title: 'かんたん見積ツールをご利用の方へ',
          seeMore: 'もっと読む',
          seeLess: '隠す',
          first_paragraph: {
            1: 'システム、アプリの開発をご検討中の方向けのかんたん見積ツールは必要な機能を選択してかんたんに概算見積をご確認いただけます。',
            2: 'シミュレーション結果はメールアドレスをご登録いただくと、検索いただいた見積を登録したメールアドレスへお届けします。',
            3: 'メールアドレス登録なしでもシミュレーションはご利用いただけます。（保存機能なし）',
            4: '※当社や他の開発会社様が受託するいかなる金額もお約束するものではありません。',
            5: '概算見積のシミュレート方法は２つ',
          },
          a: {
            head: 'A.弊社実績に基づいたデータを用いたテンプレートを使って見積を作成する',
            head2: '推奨される機能要件と非機能要件を選択するだけで概算見積を算出',
            head3: '（非エンジニアの方でもかんたんに概算見積の算出を可能にしました）',
            steps: {
              1: 'Step1　検索フィルタ―からシステムのキーワード検索またはフレームワークを選択',
              2: 'Step2　検索結果をフィルタリングすると、目的のシステム/アプリに一致する見積テンプレートが表示される',
              3: 'Step3　目的のシステム/アプリを見つけたら「テンプレートを使う」をクリック',
              4: 'Step4　テンプレートに追加機能等を追加する',
              5: 'Step5　「見積生成」ボタンをクリック',
              6: 'Step6　見積結果の送信　',
            },
          },
          b: {
            head: 'B.スクラッチ（ゼロ）から見積を作成する',
            head2: '（主にシステム開発技術関係者用）',
            steps: {
              1: 'Step1　「見積新規作成」ボタンをクリック',
              2: 'Step2　 必須フィールドに情報を入力',
              3: 'Step3　「見積生成」ボタンををクリック',
              4: 'Step4　見積結果の送信',
            },
          },
          last_paragraph: {
            1: '算出された予算が合わない場合は機能等を調整し、予算の増減をその場でご確認いただけます。',
            2: 'ぜひ貴社のシステム、アプリ開発にご活用ください。',
            3: '※最新のブラウザの利用を推奨しております。',
          },
        },
        label: {
          search: '検索フィルターを入力',
          filter: 'フィルター',
          framework: 'フレームワーク',
          clear_all_filters_btn: 'すべて選択解除',
          create_quotation_btn: '見積を作成',
          use_as_template_btn: 'テンプレートを使う',
          sorting: '並び替え：',
          system_name: 'システム名',
        },
      },
      quotation_create: {
        heading: '【システム要件】',
        label: {
          system_name: 'システム/アプリ名',
          system_name_placeholder: 'システム名を入力',
          business_model: 'ビジネスモデル',
          business_model_placeholder: 'i.e.: B2B, B2C, SaaS, P2P',
          development_type: '開発タイプ',
          num_roles: '想定ユーザータイプ/ロール数',
          devices_and_browsers: '対応ディバイス/ブラウザ',
          devices_and_browsers_placeholder: 'ディバイス／ブラウザ名',
          specify_at_least_one: '（最低1つを指定）',
          design_doc_requirement: '【設計書の要件】',
          ui_layout: 'UIレイアウト/モックアップ',
          spec_requirement: '仕様要件',
          create_design: 'デザイン作成を依頼する',
          design_provided: 'UIレイアウト/モックアップを貴社でご用意いただく',
          create_spec_doc: '仕様書の作成を依頼する',
          spec_doc_provided: '設計書を貴社でご用意いただく',
          features_and_functions: '【機能詳細】',
          framework: 'フレームワーク',
          technology: '開発言語/テクノロジー',
          function_name: '機能名',
          function_name_placeholder: '機能名を入力',
          function_type: '機能タイプ',
          function_type_placeholder: '機能対応を選択',
          num_fields: '想定フィールド/項目数',
          sub_functions: '詳細',
          add_function_btn: '機能を追加',
          cancel_btn: 'キャンセル',
          preview_quotation_btn: '見積もりプレビュー',
          step1_btn: '要件詳細を入力',
          step2_btn: '見積もりのプレビューを確認',
          step3_btn: '見積もりを生成',
        },
      },
      quotation_preview: {
        heading: '見積もりプレビュー',
        emailSentSuccessful: 'Email successfully sent.',
        label: {
          specification: '【システム要件】',
          ui_spec: '【設計書の要件】',
          system_name: 'システム名：',
          business_model: 'ビジネスモデル：',
          development_type: '開発タイプ：',
          project_design_and_planning: 'プロジェクトデザインとプラン：',
          num_roles: '想定ユーザータイプ/ロール数：',
          devices_and_browsers: '対応ディバイス/ブラウザ：',
          ballpark_estimation: '【概算（Ball-Park）見積】',
          features_and_functions: '機能一覧',
          total_mm: '合計（人月）',
          total_yen: '合計',
          back_btn: '〈　戻る　',
          generate_quotation_btn: '見積もりを生成する',
          quotation_total_note:
            '※当社や他の開発会社様が受託するいかなる金額もお約束するものではありません。',
        },
      },
      quotation_preview_modal: {
        heading: '概算（Ball-Park）見積資料　ダウンロード申請',
        common: {
          required: '（必須）',
          name: '名前を入力',
          name_placeholder: '名前',
          email: 'メールアドレス',
          email_placeholder: 'メールアドレスを入力',
          business_type: 'ビジネスタイプ',
          phone_number: '電話番号',
          phone_number_placeholder: '電話番号を入力',
          send: '送信',
          options: {
            individual: '個人',
            company: '企業',
          },
        },
        individual: {},
        company: {
          label: {
            company_name: '会社名',
            company_name_placeholder: '会社名を入力',
            department_position: '部署/役職',
            department: '部署を選択',
            department_placeholder: '部署を選択',
            position: '役職を選択',
            position_placeholder: '役職を選択',
            url: '会社URL',
            url_placeholder: '会社URLを入力',
            business_license: '営業許諾',
            business_license_placeholder: '営業許諾を入力',
            get_in_touch: '後日、Sprobeからのフォローアップのご連絡を希望する。',
            get_in_touch_2: 'ご同意いただける方のみご案内させていただきます。',
            accept_terms: '個人情報保護方針',
            accept_terms_2: 'に同意する',
          },
        },
      },
    },
    menu: {
      home: 'Home',
      about: 'About',
      inquiry: 'Inquiry',
      faq: 'FAQ',
      dashboard: 'Dashboard',
      users: 'Users',
      orders: 'Orders',
      reports: 'Reports',
      integrations: 'Integrations',
      profile: 'Profile',
      login: 'Login',
      logout: 'Logout',
      terms: 'Terms of Service',
      privacy_policy: 'Privacy Policy',
      documentation: 'Documentation',
      api_reference: 'API Reference',
      support: 'Documentation',
      styleguide: 'Styleguide',
      roles: 'Roles',
      broadcast: 'Broadcast',
    },
    table: {
      no_data: 'No data.',
    },
  },
};

export default en;
