/**
 * MUI Palette Override
 * @see https://mui.com/material-ui/customization/palette/
 */
const palette = {
  primary: {
    main: '#27c288',
    contrastText: '#fff',
    blue: '#058ED9',
  },
  secondary: {
    main: '#D14081',
    contrastText: '#fff',
  },
  success: {
    main: '#70A37F',
    contrastText: '#fff',
  },
  error: {
    main: '#FE6D73',
    contrastText: '#fff',
  },
  warning: {
    main: '#F3C969',
    contrastText: '#fff',
  },
  info: {
    main: '#9DD1F1',
    contrastText: '#fff',
  },
  transparent: {
    main: '#fff',
    dark: '#888',
  },
  grey: {
    main: '#f5f5f5',
    dark: '#f5f5f5',
  },
  background: {
    paper: '#f9f9f9',
    default: '#f9f9f9',
  },
  checkboxChecked: {
    main: '#27c288',
  },
  orange: {
    main: '#c65213',
  },
  hex2C2C2C: '#2C2C2C',
  hexf5f4ef: '#f5f4ef',
  labelColor: {
    main: 'black',
  },
  divider: 'rgba(0,0,0,0.05)',
  text: {
    primary: '#2C2C2C',
    blackLabel: 'black',
  },
};

export default palette;
